export const fields = [
    { key: 'index', label: "No", _style:'width:10px'},
    { key: "download", label: '', _style: 'min-width:75px'},
    { key: 'no_assignment', label: "No Tugas", _style:'min-width:100px' },
    { key: 'assignment_date', label: "Tanggal Tugas", _style:'min-width:100px' },
    { key: 'team', label: "Team", _style:'min-width:100px' },
    { key: 'remark', label: "Catatan", _style:'min-width:100px' },
    { key: 'status', label: "Status", _style:'min-width:100px' },
    { key: 'user', label: "Dibuat Oleh", _style:'min-width:100px' },
    { key: 'created_at', label: "Tanggal Dibuat", _style:'min-width:100px' },
    { key: 'updated_at', label: "Tanggal Diubah", _style:'min-width:100px' },
    { key: "action", label: 'Aksi', _style: 'min-width:75px'},
]
