<template>
  <div>
    <CCard>
      <CCardBody>
        <!-- <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <button class="btn btn-sm btn-primary ml-2" @click="addBarang()">
                Tambah Penugasan
              </button>
            </div>
          </div>
        </div> -->
        <div class="row justify-content-between">
          <div class="col-10">
            <div class="row mb-3">
              <label class="m-1 ml-3" for="">Search : </label>
              <input
                type="text"
                v-model="params.keyword"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Ketik disini"
              />
              <input
                type="date"
                v-model="params.fromDate"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Tanggal Awal"
              />
              <input
                type="date"
                v-model="params.toDate"
                style="max-width: 200px"
                class="form-control form-control-sm mx-2"
                placeholder="Tanggal Akhir"
              />
              <button @click="search()" class="btn btn-sm btn-success">
                Cari
              </button>
            </div>
          </div>
          <div class="col-2">
            <div class="row">
              <div class="col-12">
                <div class="input-group input-group-sm mb-3">
                  <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect01"
                      >Per Halaman</label
                    >
                  </div>
                  <select
                    class="custom-select"
                    style="max-width: 100px"
                    id="inputGroupSelect01"
                    v-model="params.row"
                    @change="getData()"
                  >
                    <!-- <option selected>Pilih...</option> -->
                    <option selected value="50">50</option>
                    <option value="100">100</option>
                    <option value="500">500</option>
                    <option value="1000">1000</option>
                    <option value="2000">2000</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-if="isSearching">
          <div class="col">
            <h3>
              <span class="badge bg-primary text-light text-bor my-auto">
                {{ searchOn }}&nbsp;&nbsp;
                <span
                  @click="searchOff"
                  class="badge bg-light text-dark text-center"
                  style="cursor: pointer"
                  >X</span
                >
              </span>
            </h3>
          </div>
        </div>
        <v-container>
    
          <v-col>
              <!-- we use this wrapper to show the second scroll above of table -->
              <div id="wrapper2">
                <div id="div2" class="width-scroll" >
                </div>
              </div>
          </v-col>
            
          <v-col>
            <CDataTable
              class="table-striped"
              :items="computedItems"
              :fields="fields"
              sorter
              style="max-height: 300px; overflow: auto;"
            >
            <template #index="{item, index}">
              <td>{{index + 1}}</td>
            </template>
              <template #download="{ item }">
                <td class="py-2">
                  <CButton
                    @click="buatSuratTugas(item)" 
                    class="mr-2"
                    color="primary"
                    square
                    size="sm"
                  >
                    Print Surat Tugas
                  </CButton>
                  <CButton
                    @click="addBarang(item)"
                    :color="clickedButtons[item.id] ? 'secondary' : 'primary'"
                    class="mr-2"
                    square
                    size="sm"
                  >
                    Pengambilan Barang
                  </CButton>
                  <CButton
                    @click="addReport(item)"
                    class="mr-2"
                    :color="item.status == 'SELESAI' ? 'secondary' : 'primary'"
                    square
                    size="sm"
                  >
                    Buat Laporan
                  </CButton>
                </td>
              </template>
              <template #action="{ item }">
                <td class="py-2">
                  <CButton
                    @click="tambahLaporan(item)"
                    class="mr-2"
                    color="warning"
                    square
                    size="sm"
                  >
                    Edit
                  </CButton>
                  
                  <CButton
                    v-if="item.id != assignment.id"
                    @click="hapus(item)"
                    color="danger"
                    square
                    size="sm"
                  >
                    Delete
                  </CButton>
                </td>
              </template>
            </CDataTable>
            <pagination
              v-if="total !== items.length"
              v-model="page"
              :records="total"
              :per-page="50"
              @paginate="pagination"
            />
          </v-col>
        </v-container>
      </CCardBody>
    </CCard>

    <!-- form item out -->
    <CModal
      size="xl"
      :title="isUpdate ? 'Edit Barang' : 'Tambah Barang'"
      centered
      :color="isUpdate ? 'success' : 'primary'"
      :show.sync="createModal"
      :closeOnBackdrop="false"
    >
    <div class="row">
        <div class="col">
          <CInput
            v-model="form.code"
            label="Code"
            placeholder="Autonumber"
            disabled
          />
          <CInput
            v-model="form.remark"
            label="Catatan"
            placeholder="ketik disini"
          />
        </div>  
        <div class="col">
          <CInput
            v-model="form.date"
            label="Date"
            type="date"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.no_assignment"
            label="No. Penugasan"
            placeholder="ketik disini"
          />
          <CInput
            v-model="form.assignment_id"
            placeholder="ketik disini"
            type="hidden"
          />
        </div>
      </div>

      <table class="table">
        <thead>
          <tr>
            <th>Nama Barang</th>
            <th>Satuan</th>
            <th>Stock</th>
            <th>Qty</th>
            <th> 
             
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in form.item_out_details" :key="'out'+index">
            <td>
              <v-select
                v-model="item.item_id"
                placeholder="Pilih Barang"
                :options="computedBarang.filter((n) => n)"
                @input="changeUnitOut($event, index)"
              ></v-select>
            </td>
            <td>
              <CInput
                v-model="item.unit"
                placeholder="Unit"
                type="text"
                disabled
              />
            </td>
            <td>
              <CInput
                v-model="item.stock"
                placeholder="Stock"
                type="text"
                disabled
              />
            </td>
            <td>
              <CInput
                v-model="item.qty"
                placeholder="Qty"
                type="number"
              />
            </td>
            <td>
              <button @click="deleteBarang(index)" class="btn btn-sm btn-danger">
                Hapus
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button @click="add" class="btn btn-sm btn-primary">
          + Tambah Barang
      </button>
      <template slot="footer">
        <div>
          <button @click="printSuratPengambilan(form.assignment_id)" v-if="form.item_out_details" class="btn btn-primary mr-3">
            Print Surat Pengambilan
          </button>
          <button @click="createModal = false" class="btn btn-secondary mr-3">
            Batal
          </button>
          <button @click="submit" v-if="!isUpdate" class="btn btn-primary">
            Tambah Barang
          </button>
          <button @click="update" v-if="isUpdate" class="btn btn-primary">
            Update Barang
          </button>
        </div>
      </template>
    </CModal>
    <!-- form item out -->

    <!-- form create report -->
    <CModal
      size="xl"
      title="Buat Laporan"
      centered
      color="primary"
      :show.sync="modalReport"
      :closeOnBackdrop="false"
    >
    <div>
      <label>No Tugas : {{ form.no_assignment }}</label>
      <hr>
      <div class="mb-5" v-for="(assignment_complaint, index) in form.assignment_complaints" :key="assignment_complaint.id+'_'+index">
        <label>No Pengaduan : {{ assignment_complaint.complaint ? assignment_complaint.complaint.no_complaint: 'Not Found' }}</label>
        <br />
        <label>Lokasi : {{ assignment_complaint.complaint ? assignment_complaint.complaint.address: 'Not Found' }}</label>
        <div class="row">
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.reason"
              label="Penyebab"
              placeholder="Penyebab"
            />
          </div>
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.action"
              label="Tindakan"
              placeholder="Tindakan"
            />
          </div>
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.total_tc"
              label="Jumlah TC"
              placeholder="Jumlah TC"
              type="number"
            />
          </div>
          <div class="col-4 mb-3">
            <div v-if="form.status == 'SELESAI'">
              <label for="">Gambar Sebelum</label>
              <br />
              <div v-if="assignment_complaint.image_after == null || assignment_complaint.image_after == 'kosong'">
                <label>Gambar Tidak Ada</label>
              </div>
              <div v-else>
                <img :src="assignment_complaint.image_after" style="width: 50%;" class="image" />
              </div>
            </div>
            <div v-else>
              <label for="">Gambar Sebelum</label>
              <input
                label="Upload Image After"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_after', assignment_complaint)"
              />
            </div>
          </div>
          <div class="col-4 mb-3">
            <div v-if="form.status == 'SELESAI'">
              <label for="">Gambar Sesudah</label>
              <br />
              <div v-if="assignment_complaint.image_before == null || assignment_complaint.image_before == 'kosong'">
                <label>Gambar Tidak Ada</label>
              </div>
              <div v-else>
                <img :src="assignment_complaint.image_before" style="width: 50%;" class="image" />
              </div>
            </div>
            <div v-else>
              <label for="">Gambar Sesudah</label>
              <input
                label="Upload Image Before"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_before', assignment_complaint)"
              />
            </div>
            
          </div>
            <CInput
              v-model="assignment_complaint.assignment_date"
              hidden
            />
          <div class="col-12">
            
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Item</th>
                  <th>Satuan</th>
                  <th v-if="form.status !== 'SELESAI'">Stock</th>
                  <th>Qty</th>
                  <th v-if="form.status !== 'SELESAI'"> 
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(usage, index_usage) in assignment_complaint.item_usage_details" :key="'usage'+index_usage+'_'+index">
                  <td>
                    {{ index_usage + 1 }}
                  </td>
                  <td>
                    <v-select
                      v-model="usage.item_id"
                      placeholder="Pilih Barang"
                      :options="computedBarang.filter((n) => n)"
                      @input="changeUnit($event, index, index_usage)"
                    ></v-select>
                  </td>
                  <td>
                    <CInput
                      v-model="usage.unit"
                      disabled
                    />
                  </td>
                    <td v-if="form.status !== 'SELESAI'">
                    <CInput
                      v-model="usage.stock"
                      disabled
                    />
                  </td>
                  <td>
                    <CInput
                      v-model="usage.qty"
                      placeholder="Qty"
                      type="number"
                    />
                  </td>
                  <td>
                    <button @click="deleteBarangUsage(index, index_usage)" class="btn btn-sm btn-danger" v-if="form.status !== 'SELESAI'">
                      Hapus
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button @click="addItem(index)" class="btn btn-sm btn-primary" v-if="form.status !== 'SELESAI'">
                + Tambah Barang
            </button>
          </div>
          
        </div>
      </div>
    </div>
    <hr>
      <template slot="footer">
        <div>
          <button @click="printLaporan(form.id)" class="btn btn-primary mr-3" v-if="form.status == 'SELESAI'">
            Print Report
          </button>

          <button @click="modalReport = false" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="createReport" class="btn btn-primary" v-if="form.status !== 'SELESAI'">
            Create Report
          </button>

        </div>
      </template>
    </CModal>
    <!-- form create report -->

    <!-- form tambah laporan -->
    <CModal
      size="xl"
      title="Tambah Laporan"
      centered
      color="primary"
      :show.sync="modalLaporan"
      :closeOnBackdrop="false"
    >
    <div>
      <label>No Tugas : {{ form.no_assignment }}</label>
      <hr>
      <div class="mb-5" v-for="(assignment_complaint, index) in form.assignment_complaints" :key="assignment_complaint.id+'_'+index">
        <label>No Pengaduan : {{ assignment_complaint.complaint ? assignment_complaint.complaint.no_complaint: 'Not Found' }}</label>
        <br />
        <label>Lokasi : {{ assignment_complaint.complaint ? assignment_complaint.complaint.address: 'Not Found' }}</label>
        <div class="row">
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.reason"
              label="Penyebab"
              placeholder="Penyebab"
            />
          </div>
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.action"
              label="Tindakan"
              placeholder="Tindakan"
            />
          </div>
          <div class="col-4">
            <CInput
              v-model="assignment_complaint.total_tc"
              label="Jumlah TC"
              placeholder="Jumlah TC"
              type="number"
            />
          </div>
          <div class="col-4 mb-3">
            <div v-if="assignment_complaint.image_after == 'kosong'">
              <label for="">Gambar Sebelum</label>
              <input
                label="Upload Image After"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_after', assignment_complaint)"
              />
              
            </div>
            <div v-else-if="form.status == 'SELESAI'">
              <label for="">Gambar Sebelum</label>
              <br />
              <div v-if="assignment_complaint.image_after == null || assignment_complaint.image_after == 'kosong'">
                <label>Gambar Tidak Ada</label>
              </div>
              <div v-else>
                <img :src="assignment_complaint.image_after" style="width: 50%;" class="image" />
              </div>
            </div>
            <div v-else>
              <label for="">Gambar Sebelum</label>
              <input
                label="Upload Image After"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_after', assignment_complaint)"
              />
            </div>
          </div>
          <div class="col-4 mb-3">
            <div v-if="assignment_complaint.image_before == 'kosong'">
              <label for="">Gambar Sesudah</label>
              <input
                label="Upload Image Before"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_before', assignment_complaint)"
              />
              
            </div>
            <div v-else-if="form.status == 'SELESAI'">
              <label for="">Gambar Sesudah</label>
              <br />
              <div v-if="assignment_complaint.image_before == null || assignment_complaint.image_before == 'kosong'">
                <label>Gambar Tidak Ada</label>
              </div>
              <div v-else>
                <img :src="assignment_complaint.image_before" style="width: 50%;" class="image" />
              </div>
            </div>
            <div v-else>
              <label for="">Gambar Sesudah</label>
              <input
                label="Upload Image Before"
                class="form-control"
                type="file"
                @change="handleFileChange($event, 'image_before', assignment_complaint)"
              />
            </div>
            
          </div>
            <CInput
              v-model="assignment_complaint.assignment_date"
              hidden
            />
          <div class="col-12">
            
            <table class="table">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Nama Item</th>
                  <th>Satuan</th>
                  <th v-if="form.status !== 'SELESAI'">Stock</th>
                  <th>Qty</th>
                  <th v-if="form.status !== 'SELESAI'"> 
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(usage, index_usage) in assignment_complaint.item_usage_details" :key="'usage'+index_usage+'_'+index">
                  <td>
                    {{ index_usage + 1 }}
                  </td>
                  <td>
                    <v-select
                      v-model="usage.item_id"
                      placeholder="Pilih Barang"
                      :options="computedBarang.filter((n) => n)"
                      @input="changeUnit($event, index, index_usage)"
                    ></v-select>
                  </td>
                  <td>
                    <CInput
                      v-model="usage.unit"
                      disabled
                    />
                  </td>
                    <td v-if="form.status !== 'SELESAI'">
                    <CInput
                      v-model="usage.stock"
                      disabled
                    />
                  </td>
                  <td>
                    <CInput
                      v-model="usage.qty"
                      placeholder="Qty"
                      type="number"
                    />
                  </td>
                  <td>
                    <button @click="deleteBarangUsage(index, index_usage)" class="btn btn-sm btn-danger">
                      Hapus
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <button @click="addItem(index)" class="btn btn-sm btn-primary" >
                + Tambah Barang
            </button>
          </div>
          
        </div>
        <hr>
        
      </div>
      <div class="row" v-if="tambahLaporanVisible">
        <div class="col-4 mb-3">
          <v-select
            placeholder="Pilih Pengaduan"
            :options="computedComplaint.filter((n) => n)"
            v-model="selectedComplaint"
          ></v-select>
        </div>
        <div class="col-4 mb-3">
          <button @click="addPengaduan()" :disabled="!selectedComplaint" class="btn btn-sm btn-primary" >
              + Tambah Laporan
          </button>
        </div>
      </div>
    </div>
    
    <hr>
      
      <template slot="footer">
        <div>
          <button @click="modalLaporan = false" class="btn btn-secondary mr-3">
            Batal
          </button>

          <button @click="createReport" class="btn btn-primary">
            Create Report
          </button>

        </div>
      </template>
    </CModal>
    <!-- form tambah laporan -->
  </div>
</template>

<script>
import * as data from "../../model/assignment"
import firebase from "firebase/compat/app"
import "firebase/auth"
import { uploadImage } from "@/utils/fileUpload";

export default {
  data() {
    return {
      tambahLaporanVisible: true,
      selectedComplaint: null,
      item_out : [],
      createModal: false,
      modalReport: false,
      modalLaporan: false,
      fields: data.fields,
      clickedButtons: {},
      isUpdate: false,
      items: [],
      complaintsdrop: [],
      assignment: [],
      page: 1,
      total: 0,
      to: 0,
      form: {
        item_out_details: [],
        assignment_complaints: [{}]
      },
      params: {
        sorttype: "desc",
        sortby: "created_at",
        row: 50,
        page: 1,
        keyword: "",
        toDate: "",
        fromDate: "",
      },
      isSearching: false,
      searchOn: "",
      verificators: [{ value: 0, label: "-" }],
      barangs: [],
      complaints: []
    }
  },
  watch: {
    'item.item_id.value': function(newVal, oldVal) {
        const selectedItem = this.computedBarang.find(item => item.value === newVal);
        if (selectedItem) {
          console.log(selectedItem.unit.unit);
          // this.changeUnit(selectedItem.unit.unit);
        }
    }
  },
  methods: {
    handleFileChange(event, type, item) {
      let selectedFile = event.target.files[0];
      var loading = this.$loading.show();

      uploadImage(selectedFile).then((resp) => {
          if (type === 'image_after') {
              item.image_after = resp; // assuming resp directly returns the URL
          } else if (type === 'image_before') {
              item.image_before = resp; // assuming resp directly returns the URL
          }
          console.log(resp);
          loading.hide();
          alert("File berhasil diupload !!");

      }).catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
      });
    },
    getComplaintNoComplaint(id) {
      const complaint = this.complaints.find(complaint => complaint.id === id);
      return complaint ? complaint.no_complaint : 'Not found';
    },
    getDataBarang() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 100,
        page: 1,
        keyword: "",
      }

      this.$store
        .dispatch("item/getItem", params)
        .then((resp) => {
          this.barangs = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataComplaintDrop() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        kecamatan: "",
        page: 1,
        status: 0,
      }

      this.$store
        .dispatch("complaint/getComplaint", params)
        .then((resp) => {
          this.complaintsdrop = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    getDataComplaint() {
      var loading = this.$loading.show()

      let params = {
        sorttype: "asc",
        sortby: "id",
        row: 50,
        kecamatan: "",
        page: 1,
        status: 2,
      }

      this.$store
        .dispatch("complaint/getComplaint", params)
        .then((resp) => {
          this.complaints = resp.data.data
          loading.hide()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    deleteBarang(index) {
      (this.form.item_out_details.splice(index, 1))
    },
    deleteBarangUsage(index, index_usage) {
      this.form.assignment_complaints[index].item_usage_details.splice(index_usage, 1);
      const barangs = this.form.assignment_complaints[index];
      this.$set(this.form.assignment_complaints, index, barangs)
      console.log(this.form.assignment_complaints[index])
    },
    onChange(event) {
      console.log(event, "event")
    },
    add() {
      this.form.item_out_details.push({
        item_id: null,
        qty: 0,
      })
    },
    addItem(index_complaint) {
      this.form.assignment_complaints[index_complaint].item_usage_details.push({
        item_id: null,
        qty: 0
      });
      const new_details = this.form.assignment_complaints[index_complaint];
      this.$set(this.form.assignment_complaints, index_complaint, new_details)
    },
    addPengaduan(index_complaint) {
      this.tambahLaporanVisible = false
      const newComplaint = {
        id: this.form.id, 
        assignment_id: this.form.assignment_complaints[0].assignment_id,
        complaint: {
          no_complaint: this.selectedComplaint.no_complaint,  
          address: this.selectedComplaint  .address   
        },
        complaint_id: this.selectedComplaint.value,
        reason: null,
        action: null,
        total_tc: null,
        image_before: 'kosong',
        image_after: 'kosong',
        assignment_date: null,
        item_usage_details: []  // Initially empty. You can add a default item here if needed
      };
    this.form.assignment_complaints.push(newComplaint);
    },
    changeUnit(unitValue, index, index_usage) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("usage/getStock", unitValue.value)
          .then((resp) => {

            this.form.assignment_complaints[index].item_usage_details[index_usage].stock = resp.data.length > 0 ? resp.data[0].qty : 0;
            this.form.assignment_complaints[index].item_usage_details[index_usage].unit = unitValue.unit;
            const new_details = this.form.assignment_complaints[index];
            this.$set(this.form.assignment_complaints, index, new_details)

            loading.hide()
          })
          .catch((e) => {
            console.log(e)
            loading.hide()
          })
    }, 
    changeUnitOut(unitValue, index) {
        var loading = this.$loading.show()
        this.$store
          .dispatch("usage/getStock", unitValue.value)
          .then((resp) => {

            this.form.item_out_details[index].stock = resp.data.length > 0 ? resp.data[0].qty : 0;
            this.form.item_out_details[index].unit = unitValue.unit;
            const new_details = this.form.item_out_details[index];
            this.$set(this.form.item_out_details, index, new_details)
            
            loading.hide()
          })
          .catch((e) => {
            console.log(e)
            loading.hide()
          })
    },
    selectImage(event) {
      console.log(event);
      this.file = event.target.files[0];
      var loading = this.$loading.show();
      uploadImage(this.file)
        .then((resp) => {
          this.form.profile_picture = resp;  
          loading.hide();
          alert("File berhasil diupload !!");
        })
        .catch((e) => {
          loading.hide();
          alert("Terjadi kesalahan !! | " + e);
        });
    },
    
    getNameVerificator(id) {
      let data = this.verificators.find((item) => {
        return item.value == id
      })
      if (data) {
        return data.label
      } else {
        return "BUKAN VERIFIKATOR"
      }
    },
    search() {
      if (this.params.keyword != "" || this.params.fromDate != ""  || this.params.toDate != "" ) {
        this.isSearching = true
        this.getData()
        this.searchOn = this.params.keyword
      } else {
        this.$toast.error("Inputan tidak boleh kosong !!")
      }
    },

    searchOff() {
      this.isSearching = false
      this.params.keyword = ""
      this.getData()
    },
    submit(item) {
      var loading = this.$loading.show()
      this.form.assignment_id = this.form.id
      this.form.item_out_details.forEach((element) => {
        element.item_id = element.item_id.value
      });
      
      this.$store
        .dispatch("out/addOut", this.form)
        .then(() => {
          this.$toast.success("Berhasil menambahkan Item")
          loading.hide()
          this.createModal = false
          this.$set(this.clickedButtons, this.form.id, !this.clickedButtons[this.form.id]);
          this.form = {
            item_out_details: [],
          }
          
          this.getData()
        })
        .catch((e) => {
          console.error(e)
          this.$toast.success(e)
          loading.hide()
        })
    },
    edit(item) {
      this.form = item
      this.isUpdate = true
      this.createModal = true
    },
    update(item) {
      var loading = this.$loading.show()

      this.form.item_out_details.forEach(detail => {
          detail.item_id = detail.item_id.value;
        });
      this.$store
        .dispatch("assignment/updateAssignment", { id: this.form.id, data: this.form })
        .then(() => {
          this.$toast.success("Berhasil merubah data assignment")
          loading.hide()
          this.createModal = false
          this.$set(this.clickedButtons, this.form.id, !this.clickedButtons[this.form.id]);
          this.form = {
            item_out_details: []
          }
          
          this.getData()
        })
        .catch((e) => {
          loading.hide()
        })
    },
    hapus(item) {
      if (confirm("Data akan dihapus !!")) {
        this.$store
          .dispatch("assignment/deleteAssignment", item.id)
          .then(() => {
            this.$toast.success("Berhasil menghapus data assignment")
            this.form = {
              item_out_details: []
            }
            this.getData()
          })
          .catch((e) => {
            this.$toast.error(e)
            loading.hide()
          })
      }
    },
    getData() {
      var loading = this.$loading.show()
      this.item_out = [];
      this.$store
        .dispatch("assignment/getAssignment", this.params)
        .then((resp) => {
          this.items = resp.data.data
          this.total = resp.data.total
          this.to = resp.data.to;
          // console.log(this.items)

          // khusus untuk checkbox
          this.selectedItems = []
          this.items.forEach((element) => {
            if (this.isSelectedAll) {
              element.select = true
              this.selectedItems.push(element.id)
            } else {
              element.select = false
              
              if (element.item_out) {
                  this.item_out.push(element.item_out); // Push the item_out to the array
              }
            }

            this.$set(this.clickedButtons, element.id, !!element.item_out);
          })
          loading.hide()

        })
        .catch((e) => {
          loading.hide()
        })
    },

    addBarang(item) {
      this.isUpdate = false

      if (item.item_out) {
        this.form = item.item_out;
        this.form.date = this.$moment(this.form.date).format("YYYY-MM-DD");
        this.form.no_assignment = this.form.assignment.no_assignment;
        console.log(this.form, "form");
      } else {
        this.form = item;
        this.form.date = this.$moment(this.form.date).format("YYYY-MM-DD");
      }

      this.createModal = true
    },
    addReport(item) {
      let processedDate = item.assignment_date; 
      let originalFormat = this.$moment(processedDate, "Do MMMM YYYY, HH:mm").format("YYYY-MM-DD HH:mm:ss");
      this.modalReport = true

      item.assignment_complaints.forEach(complaint => {
        if (complaint.Item_usage.item_usage_details && item.status == 'SELESAI') {
          complaint.item_usage_details = complaint.Item_usage.item_usage_details;
          complaint.item_usage_details.forEach(element => {
            let label = this.barangs.find((element_barang) => {
              if (element.item_id.value) {
                return element_barang.id == element.item_id.value;
              } else {
                return element_barang.id == element.item_id;
              }
            });
            let barang = {
              value: label.id,
              label: label.name,
            }
            element.item_id = barang;
            element.unit = label.unit;
          });
        } else {
          complaint.item_usage_details = [];
        }
      });

      this.form = {
        ...this.form,
        id: item.id,
        assignment_complaints: item.assignment_complaints,
        no_assignment: item.no_assignment,
        assignment_date: originalFormat,
        flag: 'laporan',
        remark: item.remark,
        team_id: item.team_id,
        user_id: item.user_id,
        status: item.status
      }

      console.log(this.form);
      
      // this.form.id = item.id
      // this.form.assignment_complaints = item.assignment_complaints;
      // this.form.no_assignment = item.no_assignment
      // this.form.assignment_date = originalFormat
      // this.form.flag = "laporan"
      // this.form.remark = item.remark
      // this.form.team_id = item.team_id
      // this.form.user_id = item.user_id
    },
    tambahLaporan(item) {
      let processedDate = item.assignment_date; 
      let originalFormat = this.$moment(processedDate, "Do MMMM YYYY, HH:mm").format("YYYY-MM-DD HH:mm:ss");
      this.modalLaporan = true

      item.assignment_complaints.forEach(complaint => {
        if (complaint.Item_usage.item_usage_details && item.status == 'SELESAI') {
          complaint.item_usage_details = complaint.Item_usage.item_usage_details;
          complaint.item_usage_details.forEach(element => {
            let label = this.barangs.find((element_barang) => {
              if (element.item_id.value) {
                return element_barang.id == element.item_id.value;
              } else {
                return element_barang.id == element.item_id;
              }
            });
            let barang = {
              value: label.id,
              label: label.name,
            }
            element.item_id = barang;
            element.unit = label.unit;
          });
        } else {
          complaint.item_usage_details = [];
        }
      });

      this.form = {
        ...this.form,
        id: item.id,
        assignment_complaints: item.assignment_complaints,
        no_assignment: item.no_assignment,
        assignment_date: originalFormat,
        flag: 'laporan',
        remark: item.remark,
        team_id: item.team_id,
        user_id: item.user_id,
        status: item.status
      }

    },
    closeModalReport() {
      this.form = {}
      this.modalReport = false
      this.createModal = false
      this.modalLaporan = false
      this.tambahLaporanVisible = true
    },
    createReport() {
        var loading = this.$loading.show()
        console.log(this.form.assignment_complaintimage_after)
        this.form.created_by = 1;
        if (confirm("Apa anda yakin akan menimpan data !!")) {
          this.$store
          .dispatch("assignment/updateAssignment", { id: this.form.id, data: this.form })
          .then(() => {
            this.$toast.success("Berhasil merubah data assignment")
            loading.hide()
            this.createModal = false
            this.modalLaporan = false
            this.selectedComplaint = null
            this.form = {
              assignment_complaints: [],
              item_out_details: []
            }
            this.closeModalReport()
            this.getDataComplaintDrop()
            this.getData()
            this.tambahLaporanVisible = true
          })
          .catch((e) => {
            loading.hide()
            this.closeModalReport()
            this.tambahLaporanVisible = true
          })
      }
    },
    pagination(page) {
      this.page = page
      this.params.page = page
      this.getData()
    },
    deleteAccountByAssignmentId(assignmentId) {
      firebase
        .auth()
        .getAssignment(assignmentId)
        .then((assignmentRecord) => {
          // Hapus akun pengguna dengan metode delete()
          return firebase.auth().deleteAssignment(assignmentRecord.uid)
        })
        .then(() => {
          // Akun berhasil dihapus
          console.log("Akun berhasil dihapus berdasarkan ID pengguna:", assignmentId)
        })
        .catch((error) => {
          // Tangani kesalahan jika ada
          console.error("Error saat menghapus akun:", error.message)
        })
    },
    getStatus(status) {
      if (status == 0) {
        return 'BARU';
      } else if (status == 1) {
        return 'PROSES';
      } else {
        return 'SELESAI';
      }
    },
    buatSuratTugas(item) {
      console.log(item.id);
      let route = this.$router.resolve({
        name: "surat-tugas-pdf",
        params: { id: item.id },
      });
      window.open(route.href, "_blank");
    },
    printLaporan(item) {
      console.log(item);
      let route = this.$router.resolve({
        name: "laporan-pemeliharran-pju-pdf",
        params: { id: item },
      });
      window.open(route.href, "_blank");
    },
    printSuratPengambilan(item) {
      let route = this.$router.resolve({
        name: "form-pengambilan-barang-pdf",
        params: { id: item},
      });
      window.open(route.href, "_blank");
    },
  },
  computed: {
    buttonClass() {
        return this.isPrimary ? 'primary' : 'secondary';
    },
    computedItems() {
      return this.items.map((item, index) => {
        return {
          index: (this.to - this.items.length) + index+1 + '.',
          ...item,
          team: item.team ? item.team.name : '-',
          status: this.getStatus(item.status),
          item_out_details: [],
          user: item.user ? item.user.full_name : '-',
          assignment: item.assignment ? item.assignment.no_assignment : '-',
          date: this.$moment(item.date).format("Do MMMM YYYY"),
          assignment_date: this.$moment(item.assignment_date).format("Do MMMM YYYY"),
          created_at: this.$moment(item.created_at).format("Do MMMM YYYY"),
          updated_at: this.$moment(item.updated_at).format("Do MMMM YYYY"),
        }
      })
    },
    computedComplaint() {
      // Extract all 'created_by' values from 'assignment_complaints' where 'complaint' is defined

      return this.complaintsdrop.map((item) => {
          return {
            value: item.id,
            label: item.no_complaint,
            no_complaint: item.no_complaint,
            address: item.address
          };
      });
    },
    computedBarang() {
      console.log('kesini', this.form);
      if (this.form.item_out_details) {
        this.form.item_out_details.forEach(element => {
          if (element.item_id && !element.item_id.value) {
            let label = this.barangs.find((element_barang) => {
              return element_barang.id == element.item_id
            });
            let barang = {
              value: element.item_id,
              label: label.name,
            }
            element.item_id = barang;
          }
        });
      }
      return this.barangs.map((item) => {
          return {
            value: item.id,
            label: item.name,
            unit: item.unit,
          };
      });
    },
  },
  mounted() {
    this.getData()
    this.getDataComplaintDrop()
    this.getDataBarang()
    this.getDataComplaint()

    let wrapper_1 = document.querySelector('.table-striped .table-responsive');
    let wrapper_2 = document.querySelector('#wrapper2');
    wrapper_1.onscroll = function() {
      wrapper_2.scrollLeft = wrapper_1.scrollLeft;
    };
      
    wrapper_2.onscroll = function() {
      wrapper_1.scrollLeft = wrapper_2.scrollLeft;
    };
  },
}
</script>

<style>

/* This is the size of the path of the scrollbar */
#wrapper2{
      width: 100%;
      overflow-x: scroll; 
      overflow-y:hidden;
  }

/* This div allow to make the scroll function and show the scrollbar */
  #div2 {
      height: 1px;
      overflow: scroll;
  }

  /* This is the size of the scrollbar */
  .width-scroll{
    width: 1500px;
  }

  @media only screen and (max-width: 767px) {

  /* Adjust styles for mobile display here */
  #wrapper2 {
    width: 100%;
    overflow-x: auto;
  }

  #div2 {
    height: 7px;
    overflow: scroll;
  }

  .width-scroll {
    width: 1500px;
  }

  }

</style>